import React from 'react'
import { Link } from 'gatsby'
import Logo from '../site_logo.png'

import './header.css'

interface MenuLink {
  name: string;
  path: string;
};

interface Props {
  menuLinks: Array<MenuLink>;
};

const Header = ({ menuLinks }: Props) => {
  return (
    <header>
      <div className="container-fluid">
        <div className="header-top row">
          <div className="header-top__title col-md-8 text-center text-md-left">
            АР-81 ЕООД - ОФИЦИАЛЕН ВНОСИТЕЛ НА БОЯДЖИЙСКИ ВАЛЯЦИ, ЧЕТКИ И ИНСТРУМЕНТИ
          </div>

          <div className="header-top__contact col-md-4 text-center text-md-right">
            info@ar81.com | (+359) 0888.50.32.68
          </div>
        </div>
      </div>

      <nav className="header-nav navbar navbar-expand-lg navbar-light bg-light">
        <a className="navbar-brand" href="#">
          <img src={Logo} class="d-inline-block align-top" alt="Logo ar81" />
        </a>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {menuLinks.map(link => {
              return (
                <li className="nav-item active" key={link.name}>
                  <Link className="nav-link" to={link.path}>{link.name}</Link>
                </li>
              )
            })}

            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Example
              </a>

              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#">Action</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">Something else here</a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
};

export default Header;
