import React from 'react';
import Header from './header'
import { StaticQuery, graphql } from 'gatsby';

import './layout.css'

interface Props {
  children: any;
};

const Layout = ({children}: Props) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteQuery {
          site {
            siteMetadata {
              menuLinks {
                name
                path
              }
            }
          }
        }
      `}
      render={data => (
        <React.Fragment>
          <Header menuLinks={data.site.siteMetadata.menuLinks}></Header>
          <main className="page-content">{children}</main>
          <div>Footer</div>
        </React.Fragment>
      )}>
    </StaticQuery>
  )
};

export default Layout;
